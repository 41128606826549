@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,500;1,500&display=swap");

.ff {
  font-family: "Albert Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

* {
  list-style-type: none;
  box-sizing: border-box;
  text-decoration: none !important;
}

.bg_red {
  background: linear-gradient(0deg, #060103 0%, #870022 100%);
}

.fs_18 {
  font-size: 18px;
}
.fs_20 {
  font-size: 20px;
}
.fs_80 {
  font-size: 80px;
}

.bg_danger {
  background-color: #820020;
}

/* .custom_zindex {
  z-index: 1234567;
}

.burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  width: 35px;
}

.burger div {
  width: 35px;
  height: 1px;
  background-color: #fff;
  border: 2px solid #fff;
  transition: 0.3s;
  margin-top: 5px;
  margin-bottom: 5px;
}

.burger.active div:nth-child(1) {
  transform: rotate(-42deg) translate(-6px, 6px);
}

.burger.active div:nth-child(2) {
  opacity: 0;
}

.burger.active div:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -6px);
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #820020;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  transition: all 400ms ease-in-out;
} */

.td {
  text-decoration: underline !important;
}

@media (max-width: 1199px) {
  .fs_80 {
    font-size: 43px;
  }
}

@media screen and (max-width: 768px) {
  /* .navmanu {
    display: block;
  }

  .d-lg-none {
    display: none;
  }

  .sidebar {
    width: 100%;
    transition: all 400ms ease-in-out;
  } */
  .fs_80 {
    font-size: 38px;
  }
  .custom_width {
    max-width: 130px;
    padding: 0 4px;
  }
}
